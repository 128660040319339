import { RouteRecordRaw } from "vue-router";
import EmptyLayout from "@/layouts/EmptyLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import { useSupportChat } from "@/composables/useSupportChat";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Landing",
    component: () =>
      import(/* webpackChunkName: "landing" */ "../views/LandingNewView.vue"),
    meta: { layout: EmptyLayout, isLoginPage: true },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/LoginView.vue"),
    meta: {
      layout: AuthLayout,
      imgBg: "eric-photo2.png",
      isLoginPage: true,
    },
  },
  {
    path: "/registration",
    name: "Registration",
    component: () =>
      import(
        /* webpackChunkName: "registration" */ "../views/auth/RegistrationView.vue"
      ),
    meta: {
      layout: EmptyLayout,
      freePage: true,
      isLoginPage: true,
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot",
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "../views/auth/ForgotPasswordView.vue"
      ),
    meta: {
      layout: AuthLayout,
      imgBg: "eric-photo2.png",
      isLoginPage: true,
    },
  },
  {
    path: "/reset-password",
    name: "Reset",
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "../views/auth/UpdatePasswordView.vue"
      ),
    meta: {
      layout: AuthLayout,
      imgBg: "eric-photo2.png",
      isLoginPage: true,
    },
  },
  {
    path: "/support",
    name: "Support",
    component: () =>
      import(/* webpackChunkName: "registration" */ "../views/ContactUs.vue"),
    meta: {
      layout: EmptyLayout,
      freePage: true,
      isLoginPage: true,
    },
  },
  {
    path: "/change-email",
    name: "changeEmail",
    component: () =>
      import(
        /* webpackChunkName: "change-email" */ "../views/profile/ChangeEmailView.vue"
      ),
    meta: {
      layout: EmptyLayout,
    },
  },
  {
    path: "/support/login",
    name: "SupportLogin",
    component: () =>
      import(
        /* webpackChunkName: "support-login" */ "../views/auth/SupportLoginView.vue"
      ),
    meta: {
      layout: EmptyLayout,
      isLoginPage: true,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/DashboardView.vue"),
  },
  {
    path: "/openMobileApp",
    name: "OpenMobileApp",
    component: () =>
      import(/* webpackChunkName: "openMobileApp" */ "../views/DeepLink.vue"),
    meta: { layout: EmptyLayout, freePage: true, isLoginPage: true },
  },
  {
    path: "/dashboard/activities-list/:date",
    name: "ActivitiesList",
    component: () =>
      import(
        /* webpackChunkName: "activities-list" */ "../views/dashboard/ActivitiesListView.vue"
      ),
    meta: { hideMobileSidebar: true },
  },
  {
    path: "/workouts",
    name: "Workouts",
    component: () =>
      import(/* webpackChunkName: "workouts" */ "../views/WorkoutsView.vue"),
    redirect: { name: "Programs" },
    children: [
      {
        path: "programs",
        name: "Programs",
        component: () =>
          import(
            /* webpackChunkName: "programs" */ "../components/resources/ProgramList.vue"
          ),
      },
      {
        path: "exercises",
        name: "Exercises",
        component: () =>
          import(
            /* webpackChunkName: "exercises" */ "../components/resources/ExerciseList.vue"
          ),
      },
    ],
  },
  {
    path: "/workouts/program/:id",
    name: "ProgramDetails",
    component: () =>
      import(
        /* webpackChunkName: "program-details" */ "../views/gameplans/GameplanDetailsView.vue"
      ),
    meta: { hideMobileSidebar: true },
  },
  {
    path: "/workouts/program/:id/exercise-list/day-:day",
    name: "DayExerciseList",
    component: () =>
      import(
        /* webpackChunkName: "day-exercise-list" */ "../views/gameplans/ExercisesListView.vue"
      ),
    meta: { hideMobileSidebar: true },
  },
  {
    path: "/workouts/exercise/:id",
    name: "ExerciseDetails",
    component: () =>
      import(
        /* webpackChunkName: "exercise-details" */ "../views/workout/ExerciseDetailsView.vue"
      ),
  },
  {
    path: "/gameplans/challenge/",
    name: "ChallengeLayout",
    component: () =>
      import(
        /* webpackChunkName: "challenge-layout" */ "../layouts/ChallengeLayout.vue"
      ),
    children: [
      {
        path: "",
        name: "ChallengeLayoutEmpty",
        redirect: { name: "ChallengeTraining" },
      },
      {
        path: "training",
        name: "ChallengeTraining",
        component: () =>
          import(
            /* webpackChunkName: "challenge-training" */ "../views/challenge/ChallengeTrainingView.vue"
          ),
        meta: { hideMobileSidebar: true },
      },
      {
        path: "recipes",
        name: "ChallengeRecipes",
        component: () =>
          import(
            /* webpackChunkName: "challenge-recipes" */ "../views/challenge/ChallengeRecipesView.vue"
          ),
      },
      {
        path: "values",
        name: "ChallengeValues",
        component: () =>
          import(
            /* webpackChunkName: "challenge-values" */ "../views/challenge/ChallengeValuesView.vue"
          ),
      },
      {
        path: "workout",
        name: "ChallengeWorkout",
        component: () =>
          import(
            /* webpackChunkName: "challenge-workout" */ "../views/challenge/ChallengeWorkoutView.vue"
          ),
      },
    ],
  },
  {
    path: "/nutrition",
    component: () =>
      import(/* webpackChunkName: "nutrition" */ "../views/NutritionView.vue"),
    redirect: { name: "Calculator" },
    children: [
      {
        path: "macro-calculator",
        name: "Calculator",
        component: () =>
          import(
            /* webpackChunkName: "calculator" */ "../components/nutrition/TheCalculator.vue"
          ),
      },
      {
        path: "recipes",
        name: "RecipeList",
        component: () =>
          import(
            /* webpackChunkName: "recipe-list" */ "../views/nutrition/RecipeListView.vue"
          ),
      },
    ],
  },
  {
    path: "/nutrition/recipe/:id",
    name: "RecipeDetails",
    component: () =>
      import(
        /* webpackChunkName: "recipe-details" */ "../views/nutrition/RecipeDetailsView.vue"
      ),
  },
  {
    path: "/database",
    name: "Database",
    component: () =>
      import(
        /* webpackChunkName: "database" */ "../layouts/DatabaseLayout.vue"
      ),
    redirect: { name: "DatabaseExercise" },
    children: [
      {
        path: "exercises",
        name: "DatabaseExercise",
        component: () =>
          import(
            /* webpackChunkName: "database-exercise" */ "../views/database/DatabaseExerciseView.vue"
          ),
      },
      {
        path: "vaults",
        name: "DatabaseVaults",
        component: () =>
          import(
            /* webpackChunkName: "database-tips" */ "../views/database/DatabaseVaultsView.vue"
          ),
      },
      {
        path: "video-courses",
        name: "DatabaseVideoCourses",
        component: () =>
          import(
            /* webpackChunkName: "database-nutrition" */ "../views/database/DatabaseVideoCoursesView.vue"
          ),
      },
    ],
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: () =>
      import(/* webpackChunkName: "calendar" */ "../views/CalendarView.vue"),
  },

  {
    path: "/resources",
    component: () =>
      import(/* webpackChunkName: "resources" */ "../views/ResourcesView.vue"),
    redirect: { name: "Vault" },
    children: [
      {
        path: "vault",
        name: "Vault",
        component: () =>
          import(
            /* webpackChunkName: "vault" */ "../components/resources/VaultList.vue"
          ),
      },
      {
        path: "progress",
        name: "Progress",
        component: () =>
          import(
            /* webpackChunkName: "progress" */ "../components/MyProgress.vue"
          ),
      },
      {
        path: "extra-accountability",
        name: "Extra-accountability",
        component: () =>
          import(
            /* webpackChunkName: "extra-accountability" */ "../components/ExtraAccountability.vue"
          ),
      },
    ],
  },

  {
    path: "/database/video-course/:id",
    name: "VideoCourseDetails",
    component: () =>
      import(
        /* webpackChunkName: "recipe-details" */ "../views/database/VideoCourseDetails.vue"
      ),
  },
  {
    path: "/database/article/:id",
    name: "Article",
    component: () =>
      import(
        /* webpackChunkName: "database-article" */ "../views/database/ArticleDetails.vue"
      ),
  },
  {
    path: "/database/workout/:id",
    name: "Workout",
    component: () =>
      import(
        /* webpackChunkName: "database-workout" */ "../views/database/WorkoutDetails.vue"
      ),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../layouts/ProfileLayout.vue"),
    children: [
      {
        path: "",
        name: "ProfileEmpty",
        redirect: { name: "EditProfile" },
      },
      {
        path: "edit",
        name: "EditProfile",
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "../views/profile/EditProfileView.vue"
          ),
        meta: { hideMobileSidebar: true, freePage: true },
      },
      {
        path: "email-settings",
        name: "EmailSettings",
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "../views/profile/EmailSettingsView.vue"
          ),
        meta: { hideMobileSidebar: true, freePage: true },
      },
      {
        path: "security-settings",
        name: "SecuritySettings",
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "../views/profile/SecuritySettingsView.vue"
          ),
        meta: { hideMobileSidebar: true, freePage: true },
      },
      {
        path: "dashboard-settings",
        name: "DashboardSettings",
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "../views/profile/DashboardSettingsView.vue"
          ),
        meta: { hideMobileSidebar: true },
      },
      {
        path: "billing",
        name: "Billing",
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "../views/profile/BillingView.vue"
          ),
        meta: { hideMobileSidebar: true, freePage: true },
      },
    ],
  },
  {
    path: "/chat",
    name: "CoachChat",
    component: () =>
      import(
        /* webpackChunkName: "chat" */ "../layouts/CoachSupportChatLayout.vue"
      ),
    meta: { coachPage: true },
    children: [
      {
        path: ":id",
        name: "ChatActive",
        component: () =>
          import(
            /* webpackChunkName: "chat-active" */ "../views/chat/CoachChatActiveView.vue"
          ),
        meta: { hideMobileSidebar: true, coachPage: true },
      },
    ],
  },
  {
    path: "/support-chat",
    name: "SupportChat",
    component: () =>
      import(
        /* webpackChunkName: "support-chat" */ "../layouts/UserSupportChatLayout.vue"
      ),
    children: [
      {
        path: "",
        name: "SupportChatEmpty",
        meta: { freePage: true },
        component: () =>
          import(
            /* webpackChunkName: "support-chat-empty" */ "../views/chat/SupportTeamView.vue"
          ),
      },
      {
        path: ":id",
        name: "SupportChatActive",
        component: () =>
          import(
            /* webpackChunkName: "support-chat-active" */ "../views/chat/SupportChatActiveView.vue"
          ),
        meta: { hideMobileSidebar: true, freePage: true },
        beforeEnter: (to, from, next) => {
          const { isSignedAtTermsOfUse } = useSupportChat();
          if (!isSignedAtTermsOfUse.value) {
            next({
              name: "SupportChatTermsOfUse",
              params: { id: to.params.id },
            });
          } else {
            next();
          }
        },
      },
      {
        path: "terms-of-use/:id",
        name: "SupportChatTermsOfUse",
        component: () =>
          import(
            /* webpackChunkName: "support-chat-terms-of-use" */ "../views/chat/SupportChatTermsOfUseView.vue"
          ),
        meta: { hideMobileSidebar: true, freePage: true },
        beforeEnter: (to, from, next) => {
          const { isSignedAtTermsOfUse } = useSupportChat();
          if (isSignedAtTermsOfUse.value) {
            next({ name: "SupportChatEmpty" });
          } else {
            next();
          }
        },
      },
    ],
  },
  {
    path: "/payment",
    name: "Payment",
    component: () =>
      import(/* webpackChunkName: "payment" */ "../views/auth/PaymentView.vue"),
    meta: { layout: EmptyLayout, freePage: true },
  },
  {
    path: "/successful-payment",
    name: "SuccessfulPayment",
    component: () =>
      import(
        /* webpackChunkName: "successful-payment" */ "../views/auth/SuccessfulPaymentView.vue"
      ),
    meta: { layout: EmptyLayout, freePage: true },
  },
  {
    path: "/email-changed",
    name: "EmailChanged",
    component: () =>
      import(
        /* webpackChunkName: "email-changed" */ "../views/EmailChangedView.vue"
      ),
    meta: { layout: EmptyLayout },
  },
  {
    path: "/onboarding",
    name: "Onboarding",
    redirect: { path: "/onboarding/step-1" },
    meta: { freePage: true },
  },

  {
    path: "/onboarding/:step",
    name: "OnboardingStep",
    component: () =>
      import(
        /* webpackChunkName: "onboarding-step" */ "../views/onboarding/OnboardingView.vue"
      ),
    meta: {
      layout: AuthLayout,
      imgBg: "eric-photo2.png",
      freePage: true,
    },
  },

  {
    path: "/pre-onboarding",
    name: "PreOnboarding",
    component: () =>
      import(
        /* webpackChunkName: "pre-onboarding" */ "../views/onboarding/PreOnboardingView.vue"
      ),
    meta: {
      layout: EmptyLayout,
      freePage: false,
    },
  },

  {
    path: "/update-calories-goal/",
    name: "UpdateCaloriesGoal",
    redirect: (to) => {
      return {
        name: "UpdateCaloriesGoalStep",
        params: { ...to.params, step: "step-1" },
      };
    },
    children: [
      {
        path: ":step",
        name: "UpdateCaloriesGoalStep",
        component: () =>
          import(
            /* webpackChunkName: "update-calories-goal-step" */ "../views/onboarding/UpdateCaloriesGoal.vue"
          ),
        meta: { layout: EmptyLayout },
      },
    ],
  },

  {
    path: "/onboarding/successful",
    name: "OnboardingSuccessful",
    component: () =>
      import(
        /* webpackChunkName: "onboarding-successful" */ "../views/onboarding/OnboardingSuccessfulView.vue"
      ),
    meta: { layout: EmptyLayout },
  },
  {
    path: "/onboarding/start-course/:id/",
    name: "OnboardingStartCourse",
    redirect: (to) => {
      return {
        name: "OnboardingStartCourseStep",
        params: { ...to.params, step: "step-1" },
      };
    },
    children: [
      {
        path: ":step",
        name: "OnboardingStartCourseStep",
        component: () =>
          import(
            /* webpackChunkName: "onboarding-start-course-step" */ "../views/onboarding/OnboardingStartCourseView.vue"
          ),
        meta: { layout: EmptyLayout },
      },
    ],
  },
  {
    path: "/tracking/:date",
    name: "Tracking",
    redirect: (to) => {
      return {
        name: "TrackingStep",
        params: { ...to.params, step: "step-1" },
      };
    },
  },
  {
    path: "/tracking/:date/:step",
    name: "TrackingStep",
    component: () =>
      import(
        /* webpackChunkName: "tracking-step" */ "../views/TrackingView.vue"
      ),
    meta: { layout: EmptyLayout },
  },
  {
    path: "/redirect-to-stripe",
    name: "RedirectToStripe",
    component: () =>
      import(
        /* webpackChunkName: "redirect-to-stripe" */ "../views/RedirectToStripeView.vue"
      ),
    meta: { layout: EmptyLayout, freePage: true },
  },
  {
    path: "/dashboard/stub-page",
    name: "StubPage",
    component: () =>
      import(/* webpackChunkName: "stub-page" */ "../views/StubPageView.vue"),
    meta: {
      layout: MainLayout,
      freePage: true,
    },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () =>
      import(
        /* webpackChunkName: "privacy-policy" */ "../views/PrivacyPolicy.vue"
      ),
    meta: { layout: EmptyLayout, isLoginPage: true },
  },
  {
    path: "/terms-of-use",
    name: "TermsOfUse",
    component: () =>
      import(/* webpackChunkName: "terms-of-use" */ "../views/TermsOfUse.vue"),
    meta: { layout: EmptyLayout, freePage: true, isLoginPage: true },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    redirect: { name: "Dashboard" },
  },
];

export default routes;
