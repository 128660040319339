import { createRouter, createWebHistory } from "vue-router";
import routes from "@/router/routes";
import { useUserStore } from "@/store/user";
import { computed, ref } from "vue";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { useCoachStore } from "@/store/coach";
import { useSupportChat } from "@/composables/useSupportChat";
import { useManageSubscription } from "@/composables/useManageSubscription";
dayjs.extend(isSameOrBefore);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();
  const coachStore = useCoachStore();
  const { isCoach } = useSupportChat();
  const freePageArr: string[] = [
    "Forgot",
    "Reset",
    "PrivacyPolicy",
    "TermsOfUse",
    "OpenMobileApp",
  ];
  const { isActiveMainSubs, isPastDueMainSubs } = useManageSubscription();
  const isLoginRoute = to.meta?.isLoginPage;
  const freePage = to.meta?.freePage;
  const isRefreshToken = computed(() =>
    isCoach.value ? coachStore.coachAccessToken : userStore.accessToken
  );
  const isAuthorized = computed(() => {
    return !!isRefreshToken.value;
  });

  if (isAuthorized.value) {
    if (isCoach.value) {
      if (!freePage && !to.meta?.coachPage) {
        return next({ name: "CoachChat" });
      }
    } else {
      const isManualSubscription = computed(() => {
        return (
          userStore.data.subscription?.isManualSubscribed &&
          !dayjs().isSameOrBefore(userStore.data.subscription?.end)
        );
      });
      if (
        isPastDueMainSubs.value &&
        (!userStore.settings.subscription.failedStatus ||
          dayjs().diff(userStore.settings.subscription.failedStatus, "day") >=
            1)
      ) {
        userStore.settings.subscription.failedStatus =
          dayjs().format("YYYY-MM-DD");
      }

      if (
        (userStore.data.subscription == null ||
          !isActiveMainSubs.value ||
          isManualSubscription.value) &&
        !freePage
      ) {
        return next({ name: "StubPage" });
      } else if (!userStore.data?.isOnboard && !freePage) {
        return next({
          name: "Onboarding",
        });
      }

      if (
        userStore.data?.isOnboard &&
        (to.name === "Onboarding" || to.name === "OnboardingStep")
      ) {
        return next({ name: "Dashboard" });
      }

      if (
        (isActiveMainSubs.value || isManualSubscription.value) &&
        to.name === "StubPage"
      ) {
        return next({ name: "Dashboard" });
      }

      if (
        (isLoginRoute && !freePageArr.includes(to.name as string)) ||
        to.meta?.coachPage
      ) {
        return next({ name: "Dashboard" });
      }
    }
  } else {
    if (!isLoginRoute) {
      return next({ name: "Login", query: { targetLink: to.fullPath } });
    }
  }
  next();
});

router.onError((error) => {
  if (
    /ChunkLoadError:.*failed./i.test(error.message) ||
    /Loading.*chunk.*failed./i.test(error.message)
  ) {
    window.location.reload();
  }
});

export default router;
