import { defineStore } from "pinia";
import userApi from "@/services/api/userApi";
import { AxiosResponse } from "axios";
import { useUserStore } from "@/store/user";
import router from "@/router";

export const useAuthStore = defineStore(
  "auth",
  () => {
    const userStore = useUserStore();

    function refreshToken(): Promise<AxiosResponse> {
      return userApi
        .refreshToken({ refreshToken: userStore.refreshToken || "" })
        .then((response) => {
          const { accessToken: _accessToken, refreshToken: _refreshToken } =
            response.data.data;
          userStore.setTokens({
            accessToken: _accessToken,
            refreshToken: _refreshToken,
          });
          return response.data.data;
        })
        .catch(() => {
          userStore.clear();
          router.push("/login");
        });
    }

    function login(payload: {
      password: string;
      email: string;
      type: string | undefined;
    }): Promise<AxiosResponse> {
      return userApi.logIn(payload).then((res) => {
        if (!payload.type) {
          userStore.setTokens({
            accessToken: res.data.data.accessToken,
            refreshToken: res.data.data.refreshToken,
          });
          userStore.data = res.data.data.user;
        }
        return res;
      });
    }

    function loginWithToken(payload: {
      accessToken: string;
      refreshToken: string;
    }) {
      userStore.setTokens(payload);
      return userStore.getMe().then((res) => {
        return res;
      });
    }

    function logout(): Promise<AxiosResponse> {
      return userApi.logOut().then((res) => {
        userStore.clear();
        return res;
      });
    }

    function signup(payload: {
      password: string;
      passwordRepeat: string;
      email: string;
      registrationCode?: string;
    }): Promise<AxiosResponse> {
      return userApi.signUp(payload).then((res) => {
        return res;
      });
    }

    return {
      refreshToken,
      login,
      loginWithToken,
      logout,
      signup,
    };
  },
  {
    persist: true,
  }
);
