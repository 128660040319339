import { computed } from "vue";
import { useMediaQuery } from "@vueuse/core";
import { useUserStore } from "@/store/user";
import router from "@/router";

export function useMigrateBanner() {
  const userStore = useUserStore();
  const isDesktop = useMediaQuery("(min-width: 991px)");
  const isAuthorized = computed<boolean>(() => {
    return !!userStore.accessToken;
  });

  const notShowForNextRoutes: string[] = [
    "SupportChat",
    "SupportChatEmpty",
    "SupportChatActive",
    "SupportChatTermsOfUse",
  ];
  const currentRoute = computed<string>(
    () => router.currentRoute.value.name as string
  );

  const showMigrateBanner = computed<boolean>(
    () =>
      isAuthorized.value && !notShowForNextRoutes.includes(currentRoute.value)
  );
  const isMigratedToApp = computed<boolean>(
    () => !!userStore?.data?.migratedToV2At
  );
  const migrateBannerHeight = computed<string>(() =>
    isDesktop.value && showMigrateBanner.value ? "74px" : "0px"
  );

  return {
    showMigrateBanner,
    isMigratedToApp,
    migrateBannerHeight,
    currentRoute,
  };
}
