import axiosClient from "./index";
import { AxiosRequestConfig, AxiosResponse } from "axios";

export default {
  logIn(payload: { password: string; email: string }): Promise<AxiosResponse> {
    return axiosClient.post("coaches-auth/sign-in", payload);
  },
  logOut(): Promise<AxiosResponse> {
    return axiosClient.delete("coaches-auth/logout");
  },
  getCoaches(): Promise<AxiosResponse> {
    return axiosClient.get("coaches");
  },
  updatePassword(payload: {
    password: string;
    newPassword: string;
    passwordRepeat: string;
  }): Promise<AxiosResponse> {
    return axiosClient.patch("coaches/password", payload);
  },
  updateProfile(payload: { fullName: string }): Promise<AxiosResponse> {
    return axiosClient.patch("coaches", payload);
  },
  updateEmail(payload: {
    newEmail: string;
    password: string;
  }): Promise<AxiosResponse> {
    return axiosClient.patch("coaches/email", payload);
  },
  getMe(): Promise<AxiosResponse> {
    return axiosClient.get("coaches/me");
  },
  uploadChatFile(
    payload: FormData,
    uploadProgressCallback?: (event: ProgressEvent) => void
  ): Promise<AxiosResponse> {
    const config: AxiosRequestConfig = {};
    if (uploadProgressCallback) {
      config.onUploadProgress = (progressEvent: ProgressEvent) => {
        uploadProgressCallback(progressEvent);
      };
    }
    return axiosClient.post("/upload/chat-attachment", payload, config);
  },
  uploadChatVideoFile(
    payload: {
      chatId: string;
      data: FormData;
    },
    uploadProgressCallback?: (event: ProgressEvent) => void
  ): Promise<AxiosResponse> {
    const config: AxiosRequestConfig = {};
    if (uploadProgressCallback) {
      config.onUploadProgress = (progressEvent: ProgressEvent) => {
        uploadProgressCallback(progressEvent);
      };
    }
    return axiosClient.post(
      `/upload/chat-attachment-video/${payload.chatId}`,
      payload.data,
      config
    );
  },

  refreshToken(payload: { refreshToken: string }): Promise<AxiosResponse> {
    return axiosClient.post("coaches-auth/refresh-token", payload);
  },
};
