import { computed } from "vue";

export const isIOSdevice = computed<boolean>(() =>
  /iPhone|iPod|iPad/.test(window.navigator.userAgent)
);

export const isAndroidDevice = computed<boolean>(() =>
  /Android/i.test(window.navigator.userAgent)
);

export const isWebView = (): boolean => {
  const userAgent = navigator.userAgent.toLowerCase();
  return /(webview|wkwebview|flutter)/i.test(userAgent);
};
